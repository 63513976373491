import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const App = ({ pageContext }) => {
    const { app } = pageContext;

    if (!app) {
        if (typeof window !== 'undefined') {
            window.location.href = '/404';
        }
        return false;
    }

    const { name, description, categories, website } = app;

    return (
        <Layout>
            <SEO title={name} description={description} />
            <section className="py-10 px-6 lg:px-0 pt-28 m-auto">
                <div className="md:flex mt-10 max-w-md m-auto md:max-w-screen-md">
                    <img src={`/boxes/${name.toLowerCase()}.png`} className="w-64 m-auto" alt={name} />

                    <div className="ml-4 text-center md:text-left md:max-w-md">
                        <h1 className="font-extrabold text-5xl">{name}</h1>
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-gray-200 text-gray-800">
                            {categories[0]}
                        </span>
                        <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">{description}</p>
                        <a href="/app/fr/register" className="button inline-block mt-4 text-center bg-gray-800 hover:bg-gray-700 text-white py-2 px-4 rounded">Installer {name} en 1 clic</a>
                        <a href={website} target="_blank" className="button inline-block mt-2 ml-2 text-center border border-gray-900 text-gray-900 hover:border-gray-600 hover:text-gray-600 py-2 px-4 rounded">Voir le site officiel</a>
                        <p className="text-sm mt-2">
                            <span role="img" aria-label="lock">🔒</span>{' '}
                            <b>Gratuit pendant 7 jours et sans engagment</b>
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default App;
